import en from "@vueform/vueform/locales/en";
import bootstrap from "@vueform/vueform/themes/bootstrap";
import fetchedConfig from "./build/fetched-config.json";
import { defineConfig, Validator } from "@vueform/vueform";

const isSharepointUpload = fetchedConfig.features.sharepoint;

let FilesUpload;

if (isSharepointUpload) {
  FilesUpload =
    require("vdr-framework/src/custom-components/files-management-sharepoint/vueform/files-upload.vue").default;
} else {
  FilesUpload =
    require("vdr-framework/src/custom-components/files-management/vueform/files-upload.vue").default;
}

const rwandaPhone = class extends Validator {
  check(value) {
    if (!value || !value.trim()) {
      return false;
    }

    const rwandaPhoneRegex = /^2507\d{8}$/;
    const match = rwandaPhoneRegex.test(value);

    return match;
  }

  get msg() {
    return "Rwanda phone number is required for SMS notifications. Should be in the format 2507XXXXXXXX";
  }
};

export default defineConfig({
  theme: bootstrap,
  locales: { en },
  locale: "en",
  elements: [FilesUpload],
  rules: {
    rwandaPhone,
  },
});
