import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/seekers",
        name: "seekers",
        component: () =>
          import("@/custom-components/seekers/seekers-list/investee-list.vue"),
      },
      {
        path: "/seeker/:id",
        name: "seeker",
        redirect: { name: "seeker-detail" },
        component: () =>
          import("@/custom-components/seekers/seeker-detail/seeker-detail.vue"),
        children: [
          {
            alias: "",
            path: "detail",
            name: "seeker-detail",
            component: () =>
              import(
                "@/custom-components/seekers/seeker-detail/views/form-submission.vue"
              ),
          },
          {
            path: "seeker-projects",
            name: "seeker-projects",
            component: () =>
              import(
                "@/custom-components/seekers/seeker-detail/views/projects-list/projects-list.vue"
              ),
          },
        ],
      },
      {
        path: "/investees",
        name: "investees",
        component: () =>
          import(
            "@/custom-components/investees/investee-list/investee-list.vue"
          ),
      },
      {
        path: "/investee/:id",
        name: "investee",
        redirect: { name: "aplication" },
        component: () =>
          import("@/custom-components/investees/wrappers/investee-detail.vue"),
        children: [
          {
            alias: "",
            path: "aplication",
            name: "aplication",
            component: () =>
              import(
                "@/custom-components/investees/wrappers/form-submission.vue"
              ),
          },
          {
            path: "onboarding",
            name: "onboarding",
            component: () =>
              import(
                "@/custom-components/investees/wrappers/onboarding-form.vue"
              ),
          },
          {
            path: "bdr",
            name: "bdr",
            component: () =>
              import(
                "@/custom-components/investees/investee-detail/views/bdr-intermediaries/bdr-intermediaries.vue"
              ),
          },
          {
            path: "potential",
            name: "potential",
            component: () =>
              import(
                "@/custom-components/investees/investee-detail/views/potential-investors/potential-investors.vue"
              ),
          },
          {
            path: "other",
            name: "other",
            component: () =>
              import(
                "@/custom-components/investees/investee-detail/views/unqualified-form.vue"
              ),
          },
          {
            path: "settings",
            name: "settings",
            component: () =>
              import(
                "@/custom-components/investees/investee-detail/views/investee-settings.vue"
              ),
          },
          {
            path: "preferences",
            name: "investee-preferences",
            component: () =>
              import(
                "@/custom-components/user-preferences/user-preferences.vue"
              ),
          },
          {
            path: "link",
            name: "investee-link",
            component: () =>
              import(
                "@/custom-components/investees/investee-detail/views/link-deal/link-deal.vue"
              ),
          },
          {
            path: "share",
            name: "share-submission",
            component: () =>
              import(
                "@/custom-components/investees/investee-detail/views/share-submission/share-submission.vue"
              ),
          },
          {
            path: "manager",
            name: "task-manager",
            component: () =>
              import(
                "@/custom-components/investees/investee-detail/views/task-manager/task-manager.vue"
              ),
          },
          {
            path: "documents",
            name: "documents",
            component: () =>
              import("@/custom-components/investees/vueform/documents.vue"),
          },
          {
            path: "diagnostics",
            name: "diagnostics",
            component: () =>
              import("@/custom-components/investees/vueform/diagnostics.vue"),
          },
        ],
      },
      {
        path: "/investee/match-feedback/:feedbackId",
        name: "match-feedback",
        component: () =>
          import(
            "@/custom-components/investees/investee-detail/views/potential-investors/feed-back.vue"
          ),
      },
      {
        path: "/intermediaries",
        name: "intermediaries",
        component: () =>
          import(
            "@/custom-components/intermediary/intermediary-list/intermediary-list.vue"
          ),
      },
      {
        path: "/intermediary/:id",
        name: "intermediary",
        redirect: { name: "intermediary-aplication" },
        component: () =>
          import(
            "@/custom-components/intermediary/wrappers/intermediary-detail.vue"
          ),
        children: [
          {
            alias: "",
            path: "",
            name: "intermediary-aplication",
            component: () =>
              import(
                "@/custom-components/intermediary/wrappers/application-form.vue"
              ),
          },
          {
            path: "preferences",
            name: "intermediary-preferences",
            component: () =>
              import(
                "@/custom-components/user-preferences/user-preferences.vue"
              ),
          },
        ],
      },
      {
        path: "/investors",
        name: "investors",
        component: () =>
          import(
            "@/custom-components/investor/investor-list/investor-list.vue"
          ),
      },
      {
        path: "/investor/:id",
        name: "investor",
        redirect: { name: "investor-aplication" },
        component: () =>
          import("@/custom-components/investor/wrappers/investee-detail.vue"),
        children: [
          {
            alias: "",
            path: "application",
            name: "investor-aplication",
            component: () =>
              import(
                "@/custom-components/investor/wrappers/form-submission.vue"
              ),
          },
          {
            path: "potential",
            name: "investor-potential",
            component: () =>
              import(
                "@/custom-components/investor/vueform/views/potential-deals.vue"
              ),
          },
          {
            path: "preferences",
            name: "investor-preferences",
            component: () =>
              import(
                "@/custom-components/user-preferences/user-preferences.vue"
              ),
          },
        ],
      },
      {
        path: "/users",
        name: "users",
        component: () =>
          import("@/custom-components/admin/user-list/user-list.vue"),
      },
      {
        path: "/user/:id",
        name: "user",
        redirect: { name: "user-info" },
        component: () =>
          import("@/custom-components/admin/user-detail/user-detail.vue"),
        children: [
          {
            alias: "",
            path: "application",
            name: "user-info",
            component: () =>
              import(
                "@/custom-components/admin/user-detail/information-form.vue"
              ),
          },
        ],
      },
      {
        path: "/matches",
        name: "matches",
        component: () =>
          import("@/custom-components/matches/matches-list/matches-list.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/new-password",
        name: "new-password",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/SetNewPassword.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/contact",
        name: "contact",
        component: () => import("@/custom-components/contact/contact.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isLoggedIn = store.getters["user/isLoggedIn"];
  const isTokenChecked = store.getters["tokens/isTokenChecked"];

  if (requiresAuth && !isLoggedIn && isTokenChecked) {
    next({
      name: "sign-in",
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
});

export default router;
